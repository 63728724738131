import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import BackgroundCircles from "../components/BackgroundCircles";
import metadataprivacy from "../images/metadata-privacy.svg";
import chroma from "chroma-js";

// markup
const IndexPage = () => {
  return (
    <main
      style={{
        fontFamily:
          '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
      }}
      // className="dark:bg-gray-900 bg-asbeige h-screen w-screen fixed overflow-scroll"
      className="flex flex-col min-h-[calc(100vh+4rem)]"
      // onScroll={handleScroll}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Anysphere</title>
        <meta
          name="description"
          content="A metadata-private communication platform."
        />
      </Helmet>
      <div className={`absolute top-0 left-0 right-0`}>
        <NavBar bigLogo={true} />
      </div>
      <div>
        <div className="px-4 flex-grow w-full grid place-content-center pb-0 xl:pb-24 mt-0 md:mt-16">
          <div className="h-48"></div>
          <h1 className="text-center font-['Lora'] lg:text-4xl text-3xl text-asbrown-dark leading-normal">
            <span className="animate-slideunderline underline decoration-[3px] underline-offset-8">
              Metadata-private
            </span>{" "}
            communication.
          </h1>
          <h3 className="text-center font-['Lora'] text-xl text-asbrown-300 mt-8 leading-normal">
            An attempt to make the internet a more secure place.
          </h3>
        </div>
      </div>
      <div className="mt-20 underline-offset-4 ">
        <div className="hidden xl:block">
          <div className="mx-auto w-fit">
            <div className="flex flex-row  ">
              <div className="max-w-[600px] px-4 text-lg leading-relaxed">
                <span className="font-bold">Anysphere Messaging</span> is a
                prototype of a metadata-private communication platform. It was
                created in the summer of 2022, and is accompanied by a{" "}
                <a
                  href="/anysphere-whitepaper.pdf"
                  className="underline italic decoration-2"
                >
                  whitepaper
                </a>{" "}
                and a{" "}
                <a
                  href="https://eprint.iacr.org/2022/1139"
                  className="underline italic decoration-2"
                >
                  security definition
                </a>
                .
                <br />
                <br />
                All code is{" "}
                <a
                  href="https://github.com/anysphere/anysphere-messaging"
                  className="underline italic decoration-2"
                >
                  open source
                </a>
                , and you can download our desktop app{" "}
                <Link to="/download" className="underline italic decoration-2">
                  here
                </Link>
                .
                <br />
                <br />
                Read{" "}
                <Link
                  to="/post-mortem"
                  className="underline italic decoration-2"
                >
                  why we stopped working on Anysphere Messaging
                </Link>
                .
              </div>
              <div className="w-24"></div>
              <div className="px-4">
                <img
                  className="h-48"
                  src={metadataprivacy}
                  alt="Metadata privacy is stronger than end-to-end encryption."
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid xl:hidden gap-20 grid-cols-1 justify-items-center">
          <div className="px-4">
            {" "}
            <img
              className="h-48"
              src={metadataprivacy}
              alt="Metadata privacy is stronger than end-to-end encryption."
            />
          </div>
          <div className="max-w-[600px] px-4">
            <span className="font-bold">Anysphere Messaging</span> is a
            prototype of a metadata-private communication platform. It was
            created in the summer of 2022, and is accompanied by a{" "}
            <a
              href="/anysphere-whitepaper.pdf"
              className="underline italic decoration-2"
            >
              whitepaper
            </a>{" "}
            and a{" "}
            <a
              href="/anysphere-security-definition.pdf"
              className="underline italic decoration-2"
            >
              security definition
            </a>
            .
            <br />
            <br />
            All code is{" "}
            <a
              href="https://github.com/anysphere/anysphere-messaging"
              className="underline italic decoration-2"
            >
              open source
            </a>
            , and you can download our desktop app{" "}
            <Link to="/download" className="underline italic decoration-2">
              here
            </Link>
            .
            <br />
            <br />
            Read{" "}
            <Link to="/post-mortem" className="underline italic decoration-2">
              why we stopped working on Anysphere Messaging
            </Link>
            .
          </div>
        </div>
      </div>
      <Footer />
      <div className={`absolute top-8 left-0 right-0 -z-50`}>
        <BackgroundCircles />
      </div>
    </main>
  );
};

export default IndexPage;
